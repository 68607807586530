import React, { memo, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { trackPageView } from 'src/utils/analytics';
import css from 'src/theme/css';
import Layout from 'src/components/Layout';
import ModuleWrapper from 'src/components/ModuleWrapper';
import TextStack from 'src/components/TextStack';
import Text from 'src/components/Text';
import Form from 'src/components/Form';
import Link from 'src/components/Link';
import useCountdown from 'src/hooks/useCountdown';
import { Grid, Box, Flex } from 'src/components/FlexBox';
import Layers from 'src/components/Layers';
import SmartButton from 'src/components/SmartButton';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import BackgroundImage from 'gatsby-background-image';
import { useResponsiveValue } from 'src/components/Breakpoints';

const linkStyle = {
  textDecoration: 'underline',
};

const paragraphText = {
  color: 'mode',
  fontFamily: 'aktiv',
  fontWeight: 'medium',
  fontSize: 2,
  lineHeight: 1.3,
  letterSpacing: '-0.025em',
  maxWidth: '35rem',
};

const CircleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68" {...props}>
    <defs>
      <linearGradient id="gradient-1" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="#ffffff00" />
        <stop offset="100%" stop-color="#ffffffff" />
      </linearGradient>
      <linearGradient id="gradient-2" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="#ffffffff" />
        <stop offset="100%" stop-color="#ffffff00" />
      </linearGradient>
    </defs>
    <path
      stroke="url(#gradient-2)"
      stroke-width="2"
      stroke-linecap="round"
      fill="none"
      d="M 34 2 A 16 16 0 0 0 34 66"
    />
    <path
      stroke="url(#gradient-1)"
      stroke-width="2"
      fill="none"
      d="M 34 2 A 16 16 0 0 1 34 66"
    />
    <path
      fill="white"
      class="icon"
      stroke-width="0"
      d="M41.292 31.445c.943.583.945 1.527 0 2.11l-13.584 8.39c-.943.583-1.708.153-1.708-.94v-17.01c0-1.102.763-1.523 1.708-.94l13.584 8.39z"
      transform="translate(1, 1)"
    />
  </svg>
);

const Title = ({ isVisible, delay, children }) => {
  return (
    <Box
      css={css({
        background:
          'linear-gradient(60deg, black 20%, #1d1ed4, #7d28e1, #9dd5f9, white 40%, white 60%, #9dd5f9, #7d28e1, #1d1ed4, black 80%)',
        backgroundPositionX: isVisible ? '50%' : '110%',
        backgroundPositionY: '50%',
        backgroundSize: '500%',
        transform: isVisible ? 'scale(1)' : 'scale(0.8)',
        display: 'inline',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        transitionDuration: '1s',
        transitionDelay: delay,
        transitionProperty: 'background-position-x, transform',
        transitionTimingFunction: ({ ease }) => ease.cubic,
      })}
    >
      {children}
    </Box>
  );
};

const RegistrationComplete = memo(({ isComplete }) => {
  const { days, hours, minutes, seconds } = useCountdown(
    '11 Feb 2021 11:00:00 PST'
  );

  return (
    <TextStack
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      css={css({
        visibility: isComplete ? 'visible' : 'hidden',
      })}
    >
      <Text
        variant="heading.xxl"
        css={css({
          transitionDuration: '800ms',
          transitionDelay: '200ms',
          transitionProperty: 'transform, opacity',
          transitionTimingFunction: ({ ease }) => ease.expo,
          transform: isComplete ? 'scale(1)' : 'scale(1.2)',
          opacity: isComplete ? 1 : 0,
          visibility: isComplete ? 'visible' : 'hidden',
        })}
      >
        Registration complete
      </Text>
      <Text
        css={css({
          ...paragraphText,
          transitionDuration: '800ms',
          transitionDelay: '300ms',
          transitionProperty: 'transform, opacity',
          transitionTimingFunction: ({ ease }) => ease.expo,
          transform: isComplete ? 'scale(1)' : 'scale(1.2)',
          opacity: isComplete ? 1 : 0,
          visibility: isComplete ? 'visible' : 'hidden',
        })}
      >
        Grab some popcorn. Our live event starts in
        <br />
        {days || '00'} days, {hours || '00'} hours, {minutes || '00'} minutes,
        and {seconds || '00'} seconds.
      </Text>
      <Text
        css={css({
          color: 'modeAlpha.5',
          transitionDuration: '800ms',
          transitionDelay: '400ms',
          transitionProperty: 'transform, opacity',
          transitionTimingFunction: ({ ease }) => ease.expo,
          transform: isComplete ? 'scale(1)' : 'scale(1.2)',
          opacity: isComplete ? 1 : 0,
          visibility: isComplete ? 'visible' : 'hidden',
        })}
      >
        <span css={css({ color: 'mode' })}>Add to</span>{' '}
        <Link
          style={linkStyle}
          url="https://calendar.google.com/calendar/u/0/r/eventedit?dates=20210211T190000Z/20210211T194500Z&text=Lights.+Camera.+Cloud.+-+Frame.io+Launch+Event&location=https://www.youtube.com/watch?v%3Du8AmHBhEQAk%26feature%3Dyoutu.be&details=Join+as+Michael+Cioni+and+Emery+Wells+premiere+Frame.io%E2%80%99s+latest+technology%E2%80%94one+that+bridges+the+gap+from+production+to+post+and+will+change+the+future+of+how+we+do+production.&sf=true"
        >
          Google
        </Link>
        {' • '}
        <Link
          style={linkStyle}
          url="https://frameio-email-marketing.s3.amazonaws.com/_email_assets/ad_hoc/c2c/2021_02_01_c2c_calendar_link.ics"
        >
          Apple Calendar
        </Link>
        {' • '}
        <Link
          style={linkStyle}
          url="https://frameio-email-marketing.s3.amazonaws.com/_email_assets/ad_hoc/c2c/2021_02_01_c2c_calendar_link.ics"
        >
          Outlook
        </Link>
      </Text>
    </TextStack>
  );
});

export default memo(({ data }) => {
  const columnCount = useResponsiveValue([1, 2, 1, 2]);
  const [isComplete, setIsComplete] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [innerRef, inner] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [outerRef, outer] = useMeasure({
    polyfill: ResizeObserver,
  });
  const scale = (outer.width / inner.width) * 0.7;
  const translateX = outer.x + outer.width / 2 - (inner.x + inner.width / 2);
  const translateY = outer.y + outer.height / 2 - (inner.y + inner.height / 2);

  useEffect(() => void trackPageView(), []);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 100);
  }, []);

  return (
    <Layout isNavTransparent={true}>
      <ModuleWrapper
        isHero={true}
        isViewportHeight={true}
        colorMode="dark"
        alignItems="center"
        justifyContent="center"
        background={{
          ...data.background,
          objectFit: 'contain',
          objectPosition: 'bottom',
          other: {
            opacity: isReady ? 1 : 0,
            transitionProperty: 'opacity',
            transitionTimingFunction: ({ ease }) => ease.cubic,
            transitionDelay: '0s',
            transitionDuration: '2s',
          },
        }}
        css={css({
          position: 'relative',
          px: 'pageMargin',
          pt: 5,
          pb: [6, null, null, 7],
        })}
      >
        <Layers>
          <Grid
            ref={outerRef}
            gx={5}
            gy={[4, null, 5]}
            gridTemplateColumns={['auto', null, '1fr 1fr']}
            gridTemplateRows="auto"
            alignItems="center"
            alignSelf="stretch"
            style={{ position: 'relative' }}
            css={css({
              transitionDuration: '800ms',
              transitionProperty: 'transform, opacity',
              transitionTimingFunction: ({ ease }) => ease.expo,
              transform: isComplete ? 'scale(0.8)' : 'scale(1)',
              opacity: isComplete ? 0 : 1,
            })}
          >
            <Box
              flexDirection="column"
              textAlign={['center', null, 'start']}
              alignItems={['center', null, 'start']}
            >
              <Flex
                ref={innerRef}
                gx="0.2em"
                css={css({
                  color: 'mode',
                  lineHeight: 'heading',
                  fontWeight: 'medium',
                  fontFamily: 'aktiv',
                  fontSize: [5, 6, '4.2vw', null, null, 8],
                  letterSpacing: '-0.035em',
                  whiteSpace: 'nowrap',
                  my: 3,
                  transform: isReady
                    ? 'translate(0, 0) scale(1)'
                    : `translate(${translateX}px, ${translateY}px) scale(${scale})`,
                  transition: isReady ? 'transform' : 'none',
                  transitionTimingFunction: ({ ease }) => ease.inOutCubic,
                  transitionDelay: isReady ? '2s' : '0s',
                  transitionDuration: isReady ? '1s' : '0s',
                })}
              >
                <Title isVisible={isReady} delay="0.5s">
                  Lights.
                </Title>
                <Title isVisible={isReady} delay="1s">
                  Camera.
                </Title>
                <Title isVisible={isReady} delay="1.5s">
                  Cloud.
                </Title>
              </Flex>
              <Text
                css={css({
                  ...paragraphText,
                  opacity: isReady ? 1 : 0,
                  transitionProperty: 'opacity',
                  transitionTimingFunction: ({ ease }) => ease.cubic,
                  transitionDelay: '3s',
                  transitionDuration: '1s',
                })}
              >
                Join us live on February 11 at 11:00 AM PST as we bring
                production into the cloud.
              </Text>
            </Box>
            <Form
              {...data.form}
              columnCount={columnCount}
              onSubmit={() => setIsComplete(true)}
              variant="dark"
              css={css({
                opacity: isReady ? 1 : 0,
                transitionProperty: 'opacity',
                transitionTimingFunction: ({ ease }) => ease.cubic,
                transitionDelay: '3.1s',
                transitionDuration: '1s',
              })}
              buttonCss={{
                color: 'white',
                position: 'relative',
                background: 'teaser',
                py: 2,
                px: 3,
                fontSize: 0,
                borderRadius: 1,
                zIndex: 1,
                '&::before': {
                  content: `''`,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: '-1',
                  borderRadius: 'inherit',
                  border: 'solid 1px transparent',
                  backgroundColor: 'rgba(0, 0, 0, 1)',
                  backgroundClip: 'padding-box',
                  transition: 'background-color 200ms ease-out',
                },
                '&:hover::before': {
                  filter: 'none',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            />
            <SmartButton
              action="Video Modal"
              videoUrl="https://youtu.be/c9i1QjHjpFs"
              css={css({
                gridColumn: ['auto', null, '1 / span 2'],
                opacity: isReady ? 1 : 0,
                transitionProperty: 'opacity',
                transitionTimingFunction: ({ ease }) => ease.cubic,
                transitionDelay: '3.2s',
                transitionDuration: '1s',
              })}
            >
              <BackgroundImage
                style={{
                  backgroundSize: 'cover !important',
                  backgroundPosition: 'center !important',
                }}
                css={css({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: ['center', null, 'flex-start'],
                  bg: 'gray900',
                  height: ['12rem', null, null, null, '16rem'],
                  px: 5,
                  borderRadius: 2,
                  overflow: 'hidden',
                  transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
                  transform: 'scale(1)',
                  cursor: 'pointer',
                  willChange: 'transform',
                  '::before, ::after': {
                    transform: 'scale(1.1)',
                    transition:
                      'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) !important',
                  },
                  'body:not(.touch) &:hover': {
                    transform: 'scale(1.05)',
                    '::before, ::after': {
                      transform: 'scale(1)',
                    },
                  },
                })}
                {...data.image}
              >
                <CircleIcon css={css({ size: '4rem', mr: 3 })} />
                <Text
                  css={css({
                    ...paragraphText,
                    color: 'mode',
                    fontFamily: 'aktiv',
                    fontSize: 2,
                    lineHeight: 1.3,
                    letterSpacing: '-0.025em',
                  })}
                >
                  Watch the trailer
                </Text>
              </BackgroundImage>
            </SmartButton>
          </Grid>
          <RegistrationComplete isComplete={isComplete} />
        </Layers>
      </ModuleWrapper>
    </Layout>
  );
});

export const pageQuery = graphql`
  query {
    form: contentfulForm(slug: { eq: "c2c-teaser-form" }) {
      ...FormFragment
    }
    background: contentfulImageGroup(slug: { eq: "c2c-teaser-background" }) {
      ...ImageGroupFragment
    }
    image: contentfulAsset(title: { eq: "c2c-teaser-button" }) {
      fluid(maxWidth: 2880, quality: 90) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
`;
